<template>
  <panel-simple class-content="p-2">
    <template #title>
      {{item.nombre}}
    </template>
    <template #title-button v-if="showButtons">
      <b-dropdown position="is-bottom-left" title="Acciones">
        <template #trigger>
          <b-button
            icon-left="ellipsis-v"
            type="is-primary is-light"
            size="is-small"
            title="Acciones"
          />
        </template>
        <b-dropdown-item title="Filtros externos" aria-role="listitem" custom>
          <switch-base v-model="item.attrs.filtros_externos">
            Filtros externos
          </switch-base>
        </b-dropdown-item>
        <b-dropdown-item v-show="item.attrs" title="Ampliar" aria-role="listitem" @click="showModal = true"
          ><b-icon icon="expand-alt"></b-icon> Ampliar</b-dropdown-item
        >
        <div v-if="isAuth">
          <b-dropdown-item title="Mover bloque a la izquierda" aria-role="listitem" @click="movePosition(item, 'i')"
            ><b-icon icon="chevron-left"></b-icon> Mover a la izquierda</b-dropdown-item
          >
          <b-dropdown-item title="Mover bloque a la derecha" aria-role="listitem" @click="movePosition(item, 'd')"
            ><b-icon icon="chevron-right"></b-icon> Mover a la derecha</b-dropdown-item
          >
          <b-dropdown-item title="Actualizar bloque" aria-role="listitem" @click="updateRow(item, true)"
            ><b-icon icon="redo"></b-icon> Actualizar</b-dropdown-item
          >
          <b-dropdown-item v-show="item.attrs" title="Editar" aria-role="listitem" @click="edit(item)"
            ><b-icon icon="edit"></b-icon> Editar</b-dropdown-item
          >
          <b-dropdown-item title="Eliminar" aria-role="listitem" @click="destroy(item)"
            ><b-icon icon="trash"></b-icon> Eliminar</b-dropdown-item
          >
        </div>
      </b-dropdown>
    </template>
    <SkeletonContentPanel v-if="loading" />
    <div v-if="!loading && item.attrs">
      <b-notification
        v-if="smsError"
        type="is-warning is-light"
        aria-close-label="Cerrar notificacion"
        role="alert"
        :message="smsError" />
      <ViewContentPanel 
        :item="item"
        @refresh="updateRow(item)" />
    </div>
    <ModalPanel 
      :item="item" 
      v-model="showModal" />
  </panel-simple>
</template>

<script>
import { nameStoreForm, nameForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../../", false, /\.name$/i) );
const code_form = nameForm( require.context("../../../", false, /\.name$/i) )
// 
import SkeletonContentPanel from "./SkeletonContentPanel.vue";
import ViewContentPanel from "./ViewContentPanel.vue";
import ModalPanel from "./ModalPanel.vue";
export default {
  props:{
    item:{
      type: Object,
      default: () => ({})
    },
    showButtons:{
      type: Boolean,
      default: () => true,
    }
  },
  components: {
    SkeletonContentPanel,
    ViewContentPanel,
    ModalPanel
  },
  data(){
    return {
      showModal: false,
      smsError: null,
    }
  },
  computed:{
    isAuth(){
      return !!this.$store.getters[`auth/user`]
    },
    watchFilter(){
      return this.$store.getters[`${name_store}/watchFilter`]
    },
    loading(){
      return this.$store.getters[`${name_store}/loading`][`cube_${this.item.id}`] || false
    },
    multiFilterRequest(){
      return this.$store.getters[`${name_store}/multiFilterRequest`]
    }
  },
  watch:{
    watchFilter(){
      this.updateRow(this.item)
    },
    'item.attrs.filtros_externos'(e){
      this.$store.dispatch(`${name_store}/save`, {id: this.item.id, filtros_externos: e})
    }
  },
  methods: {
    edit(row){
      this.$router.push({name: `${code_form}-nuevo-${row.attrs.tipo}`, query: {id: row.id}})
    },
    destroy(row){
      if (confirm(`Realmente desea eliminar "${row.nombre}"?`)) {
        this.$store.dispatch(`${name_store}/destroy`, row.id)
      }
    },
    movePosition(row, move){
      this.$store.dispatch(`${name_store}/move`, {id: row.id, move})
    },
    async updateRow({id}, forceUpdate = false){
      if(forceUpdate && this.multiFilterRequest.find(r => r.id === id)){
        this.$store.commit(`form_1623469803663/SET_MULTI_FILTER`, {id})
        return this.$store.commit(`form_1623469803663/SET_WATCH_FILTER`)
      }
      const e = await this.$store.dispatch(`${name_store}/getCubeSql`, id)
      if(!e){
        this.smsError = `Hubo un problema al cargar los datos, vuelva a intentarlo.`
      } else {
        this.smsError = null
      }
    },
  }
}
</script>