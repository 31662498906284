<template>
  <div class="columns is-flex-wrap-wrap" v-if="loading">
    <div class="column is-6" v-for="item in items" :key="item">
      <panel-simple icon-title="">
        <template #title>
          <b-skeleton animated></b-skeleton>
        </template>
        <SkeletonContentPanel />
      </panel-simple>
    </div>
  </div>
</template>
<script>
import SkeletonContentPanel from "./SkeletonContentPanel.vue";
export default {
  props:{
    loading:{
      type: Boolean,
      default: () => false
    }
  },
  components: {
    SkeletonContentPanel,
  },
  computed:{
    items(){
      return [1,2,3,4,5,6]
    }
  }
}
</script>