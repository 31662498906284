<template>
  <div class="box" v-if="filtros.length">
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-4" v-for="item in filtros" :key="item.key">
        <dropdown-date 
          v-if="item.selectedType === 'input_date'"
          theme="primary is-light"
          v-model="form[item.key]"
          size="is-normal"
          :label="item.newLabel"
          placeholder="Sin rango de fechas"
          @change="handleChange"
          :disabled="isDisabled"></dropdown-date>
        <div 
          v-else-if="item.selectedType === 'slider'"
          class="px-4">
          <b-field 
            :label="item.newLabel">
            <slider-filter
              :item="item"
              v-model="form[item.key]" 
              @customchange="handleChange"
              :disabled="isDisabled" />
          </b-field>
        </div>
        <select-search-base
          v-else-if="item.selectedType === 'select'"
          :label="item.newLabel"
          v-model="form[item.key]"
          @input="handleChange"
          :options="item.elements"
          :disabled="isDisabled" />
        <select-search-multiple-uri
          v-else
          :label="item.newLabel"
          :uri="item.uri"
          v-model="form[item.key]"
          @change="handleChange"
          :disabled="isDisabled" />
      </div>
    </div>
  </div>
</template>
<script>
import { nameStoreForm, dateToTimestamp, deepEqual } from "@/utils/helpers";
import SliderFilter from './SliderFilter.vue';
const name_store = nameStoreForm( require.context("../../../", false, /\.name$/i) );
export default {
  components: { SliderFilter },
  data(){
    return {
      original: {}
    }
  },
  computed:{
    isDisabled(){
      return !this.$store.getters[`auth/user`]
    },
    getters(){
      return key => this.$store.getters[`${name_store}/${key}`]
    },
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    filtros(){
      return this.dashboard.attrs.filtros || []
    },
    form:{
      get(){
        return this.dashboard.attrs.form_filtros || {}
      },
      set(e){
        const c = {
          id: this.dashboard.id,
          attrs: this.dashboard.attrs
        }
        c.attrs.form_filtros = e
        this.$store.commit(`form_1617124496702/SET_UPDATE_ROW`,c)
      }
    }
  },
  created() {
    this.original = JSON.parse( JSON.stringify(this.dashboard) )
  },
  methods: {
    async handleChange(){
      const e = Object.entries({...this.form}).reduce((a,[k,v]) => (v == null ? a : (a[k] = v, a)), {})
      if(deepEqual(this.original.attrs.form_filtros, e)) return;
      // 
      if(e.id_date && e.id_date.length){
        e.id_date = e.id_date.map(e => dateToTimestamp(e))
      }
      if(e.id_ano && e.id_ano.length){
        e.id_ano = e.id_ano.filter(e => e)
      }
      if(e.id_mes && e.id_mes.length){
        e.id_mes = e.id_mes.filter(e => e)
      }
      // 
      this.$store.commit(`form_1623469803663/SET_MULTI_FILTER`)
      await this.$store.dispatch(`form_1617124496702/save`,{
        id: this.dashboard.id,
        form_filtros: e,
      })
      this.$store.commit(`${name_store}/SET_WATCH_FILTER`)
      this.original = JSON.parse( JSON.stringify(this.dashboard) )
    }
  }
}
</script>