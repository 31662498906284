<template>
  <div class="box has-text-centered">
    <b-icon icon="chart-pie" style="font-size: 9rem; height:auto; width:auto;"></b-icon>
    <h2 class="is-size-3">No hay datos para mostrar.</h2>
    <p class="my-2">
      Comienza agregando un bloque de datos.
    </p>
    <b-button 
      type="is-info is-light" 
      class="mx-1" 
      icon-left="plus" 
      rounded
      tag="router-link"
      :to="toGrafico">Nuevo gráfico</b-button>
    <b-button 
      type="is-info is-light" 
      class="mx-1" 
      icon-left="plus" 
      rounded
      tag="router-link"
      :to="toMedidor">Nuevo medidor</b-button>
    <b-button 
      type="is-info is-light" 
      class="mx-1" 
      icon-left="plus" 
      rounded
      tag="router-link"
      :to="toNumero">Nuevo número</b-button>
  </div>
</template>
<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../../", false, /\.name$/i) );
export default {
  computed:{
    toGrafico(){
      return this.$store.getters[`${name_store}/toGrafico`](this.$route.params.id)
    },
    toMedidor(){
      return this.$store.getters[`${name_store}/toMedidor`](this.$route.params.id)
    },
    toNumero(){
      return this.$store.getters[`${name_store}/toNumero`](this.$route.params.id)
    },
  }
}
</script>