<template>
  <div>
    <SkeletonPanels :loading="loading" />
    <div class="columns" v-if="!loading">
      <div class="column">
        <FilterPanel />
      </div>
    </div>
    <div class="columns is-flex-wrap-wrap" v-if="!loading" >
      <div 
        v-for="item in panels" 
        :key="item.id"
        :class="`column ${sizePanel(item.attrs)}`">
        <ViewPanel :item="item" />
      </div>
    </div>
    <div class="columns is-flex-wrap-wrap" v-if="!loading && !panels.length">
      <div class="column" >
        <EmptyPanel />
      </div>
    </div>
  </div>
</template>

<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../../", false, /\.name$/i) );
// 
import SkeletonPanels from "./SkeletonPanels.vue"
import EmptyPanel from "./EmptyPanel.vue"
import ViewPanel from "./ViewPanel.vue"
import FilterPanel from './FilterPanel.vue'
export default {
  components:{
    SkeletonPanels,
    EmptyPanel,
    ViewPanel,
    FilterPanel,
  },
  computed:{
    user(){
      return this.$store.getters[`auth/user`]
    },
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    loading(){
      return this.$store.getters[`${name_store}/loading`].all || false
    },
    panels(){
      return this.$store.getters[`${name_store}/all_rows`]
    },
    sizePanel(){
      return ({ancho} = {}) => {
        if(!ancho || ancho === 1) return `is-4`
        if(ancho === 2) return `is-6`
        return `is-12`
      }
    }
  },
  created() {
    const e = !this.user ? {code_padre: this.dashboard.codigo} : {id_padre: this.dashboard.id}
    this.$store.dispatch(`${name_store}/all`, e)
  },
}
</script>