<template>
  <div class="has-text-justified">
    <div class="is-inline-block mx-4" v-for="item in items" :key="item.id">
      <p class="has-text-weight-semibold" :style="styleValor(item)">
        {{item.settings.before}}{{item.settings.value || 0}}{{item.settings.after}}
      </p>
      <p class="has-text-centered" :style="styleText(item)">
        {{item.newLabel}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    items:{
      type: Array,
      default: () => [],
    }
  },
  computed: {
    fontSize(){
      return item => parseFloat(item.settings.size || 30)
    },
    styleValor(){
      return item => ({
        fontSize: `${this.fontSize(item)}px`
      })
    },
    styleText(){
      return item => {
        const size = this.fontSize(item) * 0.7
        return {
          color: item.settings.color || "#000000",
          fontSize: `${size}px`
        }
      }
    },
  }
}
</script>