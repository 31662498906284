<template>
  <div class="columns is-flex-wrap-wrap">
    <div class="column" v-for="(row,index) in item.attrs.usando" :key="row.id">
      <p class="pl-2 is-size-6 has-text-weight-semibold">{{row.newLabel}}</p>
      <g-gauge-chart :results="item.attrs.results[index]" :settings="row.settings" />
      <p class="pl-5 is-size-5 has-text-weight-bold">
        {{row.settings.before}}{{item.attrs.results[index][1] || 0}}{{row.settings.after}}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    item:{
      type: Object,
      default: () => ({}),
    }
  }
}
</script>