<template>
  <div>
    <b-skeleton v-if="loading" animated></b-skeleton>
    <b-slider 
      v-else
      v-model="form"
      :min="min" 
      :max="max" 
      :step="1" 
      size="is-medium"
      ticks
      tooltip
      tooltip-always
      v-bind="$attrs" 
      v-on="$listeners"
      @dragend="$emit('customchange')"
    >
      <b-slider-tick :value="min">{{min}}</b-slider-tick>
      <b-slider-tick :value="max">{{max}}</b-slider-tick>
    </b-slider>
  </div>
</template>
<script>
export default {
  props:{
    value:{
      type: Array,
      default: () => []
    },
    item:{
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return {
      loading: true,
      form: this.value,
      min: this.item.min,
      max: this.item.max,
    }
  },
  watch:{
    form:{
      handler(e){
        this.$emit("input", e)
      },
      deep: true,
    }
  },
  async created() {
    if(this.item.uri){
      const e = await this.$store.dispatch(`form_1623469803663/requestSliderCache`, {key: this.item.key, uri: this.item.uri})
      if(e && e.length){
        this.min = parseFloat(e[0].value)
        if(e.length === 1){
          this.max = parseFloat(e[0].value)
        } else {
          this.max = parseFloat(e.slice(-1)[0].value)
        }
        if(!this.form.length || !this.form[0]){
          this.form = [this.min, this.max];
        }
      }
    }
    this.loading = false
  }
}
</script>