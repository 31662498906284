<template>
  <b-modal
      v-model="show"
      has-modal-card
      full-screen
      :can-cancel="false">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{item.nombre}}</p>
      </header>
      <section class="modal-card-body p-1">
        <ViewContentPanel 
          :item="item" 
          show-modal
          @onImageUri="uri => imgUri = uri" />
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <a v-if="imgUri" :download="`${item.nombre}.png`" :href="imgUri" class="button btn btn-primary" >
          <b-icon icon="download" class="mr-1"></b-icon> Descargar
        </a>
        <a v-if="isTable" target="_blank" :href="urlExportToExcel" class="button btn btn-primary" >
          <b-icon icon="download" class="mr-1"></b-icon> Descargar excel
        </a>
        <b-button label="Cerrar" icon-left="times" @click="show = !show" />
      </footer>
    </div>
  </b-modal>
</template>
<script>
import { ExportExcelService } from '../../../services'
import ViewContentPanel from "./ViewContentPanel.vue"
export default {
  props:{
    value:{
      type: Boolean,
      default: () => false,
    },
    item:{
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return {
      imgUri: null,
    }
  },
  components:{
    ViewContentPanel,
  },
  computed:{
    show:{
      get(){
        return this.value
      },  
      set(e){
        this.$emit("input", e)
      }
    },
    isTable(){
      return this.item && this.item.attrs && this.item.attrs.tipo === "grafico" && Number(this.item.attrs.tipo_grafico) === 6;
    },
    urlExportToExcel(){
      return ExportExcelService(this.item);
    }
  },
}
</script>