<template>
  <div>
    <div class="mb-1" v-if="item.attrs.rango_fechas && item.attrs.rango_fechas.length">
      <dropdown-date 
        theme="primary is-light"
        v-model="date_filter"
        size="is-small"
        label="Rango de fechas"
        placeholder="Sin rango de fechas"></dropdown-date>
    </div>
    <div v-if="item.attrs.tipo === `grafico`">
      <div v-if="item.attrs.results && item.attrs.results.length > 1">
        <g-area-chart 
          v-if="item.attrs.tipo_grafico === 1"
          :results="item.attrs.results"
          :full-height="showModal"
          :selection="selection" 
          @onselect="handleSelect"
          @onImageUri="uri => $emit('onImageUri', uri)" />
        <g-bar-chart 
          v-if="item.attrs.tipo_grafico === 2"
          :results="item.attrs.results"
          :full-height="showModal"
          :selection="selection" 
          @onselect="handleSelect"
          @onImageUri="uri => $emit('onImageUri', uri)" />
        <g-column-chart 
          v-if="item.attrs.tipo_grafico === 3"
          :results="item.attrs.results"
          :full-height="showModal"
          :selection="selection" 
          @onselect="handleSelect"
          @onImageUri="uri => $emit('onImageUri', uri)" />
        <g-line-chart 
          v-if="item.attrs.tipo_grafico === 4" 
          :results="item.attrs.results"
          :full-height="showModal"
          :selection="selection" 
          @onselect="handleSelect"
          @onImageUri="uri => $emit('onImageUri', uri)" />
        <g-pie-chart 
          v-if="item.attrs.tipo_grafico === 5"
          :results="item.attrs.results"
          :full-height="showModal"
          :selection="selection" 
          @onselect="handleSelect"
          @onImageUri="uri => $emit('onImageUri', uri)" />
        <g-table-chart 
          v-if="item.attrs.tipo_grafico === 6"
          :results="item.attrs.results"
          :full-height="showModal"
          :selection="selection" 
          @onselect="handleSelect" />
      </div>
      <div class="p-4 has-text-centered" v-else>
        <h3 class="has-text-danger is-size-4">No hay datos para mostrar</h3>
      </div>
    </div>
    <div v-if="item.attrs.tipo === `medidor`">
      <ViewContentMedidor :item="item" />
    </div>
    <div v-if="item.attrs.tipo === `numero`" class="p-2">
      <ViewContentNumero :items="item.attrs.usando || []" />
    </div>
  </div>
</template>
<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../../", false, /\.name$/i) );
// 
import ViewContentNumero from "./ViewContentNumero.vue"
import ViewContentMedidor from "./ViewContentMedidor.vue"
export default {
  props:{
    item:{
      type: Object,
      default: () => ({})
    },
    showModal:{
      type: Boolean,
      default: () => false,
    }
  },
  components: {
    ViewContentNumero,
    ViewContentMedidor,
  },
  computed:{
    date_filter:{
      get(){
        return this.item.attrs.rango_fechas || []
      },
      async set(e){
        await this.$store.dispatch(`${name_store}/save`, {id: this.item.id, rango_fechas: e})
        this.$emit("refresh")
      }
    },
    selection(){
      return this.$store.getters[`form_1623469803663/multiFilter`].find(e => e.id === this.item.id)
    }
  },
  methods: {
    handleSelect({fila, columna}){
      if(this.showModal) return
      // 
      if(fila || columna){
        const {columnas = []} = this.item.attrs
        if(!columnas.length) columna = null
        if(fila || columna){
          this.$store.commit(`form_1623469803663/SET_MULTI_FILTER`, {id: this.item.id, fila, columna})
          this.$store.commit(`form_1623469803663/SET_WATCH_FILTER`)
        }
      } else {
        if(this.selection){
          this.$store.commit(`form_1623469803663/SET_MULTI_FILTER`, {id: this.item.id})
          this.$store.commit(`form_1623469803663/SET_WATCH_FILTER`)
        }
      }
    }
  }
}
</script>